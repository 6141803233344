<template>

  <div class="row" ref="projectPrincipleForm">

    <div class="col-xs-12  col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">

                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                            vid="project_id"
                            rules="required"
                            name="The Project"
                            v-slot="{ passed, failed,errors }">
                      <fg-select
                              name="project_id"
                              size="large"
                              filterable
                              clearable
                              placeholder="Project"
                              :error="failed ? errors[0]: null"
                              :input-classes="'select-default'"
                              :label="'Project'"
                              :list="projectList"
                              :listItemLabel="'title'"
                              :listItemValue="'id'"
                              v-model="formData.project_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
               <div class="row">
                 <div class="col-md-6">
                   <ValidationProvider
                           vid="title"
                           rules="required"
                           name="The Title"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Title"
                               name="title"
                               fou
                               @keyup="generateURL"
                               v-model="formData.title">
                     </fg-input>
                   </ValidationProvider>
                 </div>
                 <div class="col-md-6">
                   <ValidationProvider
                           vid="url"
                           rules="required"
                           name="The URL"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="URL"
                               name="url"
                               fou
                               v-model="formData.url">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="brief"
                      rules="required"
                      name="The Brief"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="brief"
                                name="Brief"
                                fou
                                v-model="formData.brief">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <label>Thumbnail</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.thumbnail"
                  >
                  </prime-uploader>
                </div>
                <div class="form-group">
                  <label>Cover Image</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.cover_image"
                  >
                  </prime-uploader>
                </div>
                <div class="form-group">
                  <label>PDF</label>
                  <prime-pdf-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.pdf"
                  >
                  </prime-pdf-uploader>
                </div>
              </div>

              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Text</label>
                  <editor
                    v-model="formData.text"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <fg-input type="text"
                              label="Meta Title"
                              name="meta_title"
                              v-model="formData.meta_title">
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-text type="text"
                             label="Meta Description"
                             name="meta_description"
                             v-model="formData.meta_description">
                    </fg-text>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">

                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/project-principles/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import {PrimePdfUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor,
    PrimePdfUploader,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formData: {
        project_id: "",
        title: "",
        url: "",
        thumbnail: "",
        cover_image: "",
        pdf: "",
        brief: "",
        text: "",
        meta_title: "",
        meta_description: "",
        is_active: true,

      },

      projectList: [],

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  created(){
      const path = window.location.href;
      this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.projectPrincipleForm
    });

      let data = {
          'lang': this.formData.lang
      }
    this.axios.post("project-principles/builder", data).then((response) => {
      this.projectList = response.data.projects;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Principle";
        this.getPrinciple();
      } else {
        this.editMode = false;
        this.formTitle = "Add Principle";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPrinciple() {
      let data = {
        'id' : this.id,
        'lang' : this.formData.lang
      };
      this.axios.post("project-principles/get",data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Principle Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("project-principles/update/" + this.id, this.formData);
        successMessage = "Principle Updated Successfully";
      } else {
        request = this.axios.post("project-principles/create", this.formData);
        successMessage = "Principle Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/project-principles/list/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },

    getBannerVideoInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_video"]) {
        return this.mediaInfo["panel_video"];
      } else {
        return "";
      }
    },
    generateURL() {

      let newUrl = '';
      if (this.formData.title) {
        this.formData.meta_title = this.formData.title;
        this.formData.meta_description = this.formData.title;
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    },

  }
}
</script>
